<template>
  <div v-if="tabs && tabs.length" class="vaimo-tabs" :class="'view-' + view">
    <div
      v-if="!hideIfOne || (hideIfOne && tabs.length > 1)"
      class="vaimo-tabs__tabs-wrapper"
      :class="{ sticky: sticky, 'with-border-bottom': borderBottomVisible }"
    >
      <div class="vaimo-tabs__tabs">
        <div role="tablist" aria-label="Slider Tabs">
          <button
            v-for="(tab, index) in tabs"
            :id="`ID_${tab.id}`"
            :key="tab.id"
            ref="tabRefs"
            :title="tab.title"
            :aria-controls="tab.id"
            :aria-label="tab.title"
            :class="{ active: currentTab === index }"
            role="tab"
            :tabindex="currentTab === index ? '0' : '-1'"
            class="vaimo-tabs__tab"
            @click="changeTab(index)"
            @keydown="onKeyDown($event, index)"
          >
            <span>{{ tab.title }}</span>
          </button>
        </div>
      </div>
      <div class="vaimo-tabs__tabs-shader"></div>
      <div class="vaimo-tabs__tabs-shader opposite"></div>
    </div>

    <div
      v-if="view === 'tabs'"
      :id="`tab${currentTab}`"
      tabindex="0"
      :aria-labelledby="`ID_tab${currentTab}`"
      role="tabpanel"
      class="vaimo-tabs__content"
    >
      <slot :name="tabs[currentTab].id"></slot>
    </div>

    <div v-else-if="view === 'anchors'" class="vaimo-tabs__content">
      <div
        v-for="(tab, i) in tabs"
        :id="tab.id + '-content'"
        :key="tab.id"
        :data-tab-id="tab.id"
        :data-tab-index="i"
        class="vaimo-tabs__content-part"
      >
        <slot :name="tab.id"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import {
  nextTick,
  onMounted,
  onUnmounted,
  ref,
  watch
} from '@nuxtjs/composition-api';

export default {
  name: 'VaimoTabs',
  props: {
    tabs: {
      type: Array,
      required: true
    },
    hideIfOne: {
      type: Boolean,
      required: false,
      default: true
    },
    sticky: {
      type: Boolean,
      required: false,
      default: false
    },
    borderBottomVisible: {
      type: Boolean,
      required: false,
      default: false
    },
    activeTabIndex: {
      type: Number,
      required: false,
      default: 0
    },
    view: {
      type: String,
      required: false,
      validator(value) {
        return ['tabs', 'anchors'].includes(value);
      },
      default: 'tabs'
    }
  },
  emits: ['change'],
  setup(props, { emit }) {
    const SCROLL_TIMEOUT_DURATION = 700;
    const NOT_FOUND_INDEX = -1;

    watch(
      () => props.activeTabIndex,
      (newValue) => {
        changeTab(newValue);
      }
    );
    const currentTab = ref(props.activeTabIndex);
    const changeTab = (index) => {
      currentTab.value = index;
      emit('change', index);
      nextTick(() => {
        document.querySelector('.vaimo-tabs__tab.active').scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center'
        });
      });

      if (props.view === 'anchors') {
        const id = props.tabs?.[index]?.id;
        if (id) scrollToTab(id);
      }
    };

    const tabRefs = ref(null);

    const onKeyDown = (event) => {
      const { key } = event;
      if (key === 'ArrowLeft' || key === 'ArrowRight') {
        const currentIndex = props.tabs.findIndex(
          (tab) => tab.id === `tab${currentTab.value}`
        );

        if (currentIndex !== NOT_FOUND_INDEX) {
          let newIndex;
          if (key === 'ArrowLeft') {
            newIndex =
              (currentIndex - 1 + props.tabs.length) % props.tabs.length;
          } else {
            newIndex = (currentIndex + 1) % props.tabs.length;
          }

          changeTab(newIndex);
          tabRefs.value[newIndex].focus();
        }
      }
    };

    function getTopPosition(element) {
      let topPosition = 0;
      while (element) {
        topPosition +=
          element.offsetTop - element.scrollTop + element.clientTop;
        element = element.offsetParent;
      }
      return topPosition;
    }

    let scrollTimeout; // container for Timeout function to be able to clear it later
    let scrollTarget; // ID of the tab that will be scrolled to if tab clicked
    function scrollToTab(tabId, _direction) {
      if (scrollTarget === tabId) return;
      scrollTarget = tabId;

      // Clear any previous scroll timeout if it exists to prevent multiple scrolls
      clearTimeout(scrollTimeout);

      // Define target's tab content topPosition
      const element = document.getElementById(tabId + '-content');
      const topPosition = getTopPosition(element);

      // Direction to scroll is required to be defined to make scroll one-direction only.
      // To be able to cancel scrolling page back to the target (pag shifts)
      // if the page was scrolled a little bit more than expected
      // due to appearing\disappearing of other DOM elements
      const direction =
        _direction || (window.scrollY < topPosition ? 'down' : 'up');

      if (
        !topPosition ||
        (direction === 'down' && window.scrollY >= topPosition) ||
        (direction === 'up' && window.scrollY <= topPosition)
      )
        return;

      // Repeat the scroll to the target tab in case it is not reached yet by previous click
      // It is required to do to cover the case when other DOM elements appear and shift target's topPosition
      scrollTimeout = setTimeout(() => {
        if (!scrollTarget) return; // See enableTabsObserver below for more details
        scrollToTab(tabId, _direction);
      }, SCROLL_TIMEOUT_DURATION);

      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }

    // TABS OBSERVER LOGIC
    // Makes active the tab which is visible in the viewport
    let observer;
    const enableTabsObserver = () => {
      if (props.view === 'anchors') {
        observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              currentTab.value = +entry.target.dataset.tabIndex;
              const tabId = entry.target.dataset?.tabId;

              // Check if the tab-scroll has reached the target section
              // and clear scrollTarget to prevent additional scrollToTab() by timeout
              if (tabId && tabId === scrollTarget) {
                clearTimeout(scrollTimeout);
                scrollTarget = null;
              }
            }
          });
        });

        const sections = document.querySelectorAll('.vaimo-tabs__content-part');
        for (let i = 0; i < sections.length; i++) {
          observer.observe(sections[i]);
        }
      }
    };

    onMounted(() => {
      enableTabsObserver();
    });

    onUnmounted(() => {
      observer?.disconnect();
    });

    return {
      currentTab,
      changeTab,
      onKeyDown,
      tabRefs
    };
  }
};
</script>

<style scoped lang="scss">
.vaimo-tabs {
  &__content {
    &-part {
      scroll-margin-block-start: 120px;
    }

    .vaimo-slider {
      min-height: 600px;

      @include for-desktop {
        min-height: 500px;
      }
    }
  }

  &__tabs-wrapper {
    @apply mb-base m-and-l:mb-md;
    &.sticky {
      position: sticky;
      top: 82px;
      z-index: 4;
      background: #fff;
      padding-top: 15px;
      padding-bottom: 15px;
      margin-bottom: 5px;
      border-top: 1px solid #e8e8e8;
      transition: top 0.3s;

      @include for-screen-m-and-l {
        top: 0;
      }
    }
    &.with-border-bottom {
      border-bottom: 1px solid #e8e8e8;
    }
  }

  &__tabs-shader {
    position: absolute;
    top: 0;
    width: 30px;
    height: 100%;
    background: -moz-linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background: -webkit-linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background: linear-gradient(
      to right,
      rgb(255 255 255) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 );
    &.opposite {
      right: 0;
      left: unset;
      transform: rotate(180deg);
    }
  }

  &__tabs {
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }

    div {
      @apply flex pl-sm ml-auto mr-auto m-and-l:justify-center;
      width: fit-content;
      font-size: 16px;
      line-height: 135%;
      @include for-screen-s {
        font-size: 14px;
      }

      button {
        @apply whitespace-nowrap uppercase cursor-pointer;

        &:last-child {
          @apply s:mr-md;
        }

        span {
          @apply inline-block px-sm;
          font-weight: 300;
          color: #808080;
        }

        &.active span {
          color: #000;
          font-weight: 400;
        }

        &:focus {
          outline: none;
        }

        &:focus-visible {
          outline: 2px solid #000;
          outline-offset: -1px;
        }
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 20px;
      height: 100%;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

.scroll-up {
  .vaimo-tabs__tabs-wrapper.sticky {
    transition: top 0.4s;
    top: 82px;
    @include for-screen-m-and-l {
      top: 64px;
    }
  }

  .vaimo-tabs__tabs-border {
    transition: top 0.4s;
    top: 133px;
    @include for-screen-m-and-l {
      top: 118px;
    }
  }
}

.scroll-down {
  .vaimo-tabs__tabs-wrapper.sticky {
    transition: top 0.4s;
    top: 0;
    @include for-screen-m-and-l {
      top: 0;
    }
  }

  .vaimo-tabs__tabs-border {
    transition: top 0.4s;
    top: 51px;
    @include for-screen-m-and-l {
      top: 54px;
    }
  }
}
</style>
